import React, { useState } from "react";

import {
  Layout,
  Badge,
  Avatar,
  Divider,
  Typography,
  Popover,
  Modal,
  Select,
} from "antd";
import { PageContainer } from "@ant-design/pro-layout";

import Logo from "../assets/full-logo.svg";
import {
  UserOutlined,
  TranslationOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

import UserStore from "../utilities/UserStore";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const { Header, Footer, Content } = Layout;
const { Text, Link } = Typography;
const { Option } = Select;

const Main = (props) => {
  const { t, i18n } = useTranslation();
  const [hasTitle, setHasTitle] = useState({});
  const [translationModalVisible, setTranslationModalVisible] = useState(false);
  const setHeader = (header) => {
    setHasTitle(header);
  };
  
  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      //TODO: We could add properties managed by the Layout e.g. loading spinner, help drawer, etc)
      return React.cloneElement(child, { setHeader: setHeader });
    }
    return child;
  });
  const onLogout = async () => {
    let result = await UserStore.logout();
    if (result.code === "success") {
      window.location.replace("/");
    }
  };
  const openTranslationModal = () => {
    setTranslationModalVisible(true);
  };
  const closeTranslationModal = () => {
    setTranslationModalVisible(false);
  };

  const selectLanguage = (value) => {
    i18n.changeLanguage(value);
  };

  return (
    <>
      <Layout>
        <Header className="penta-header">
          <img
            style={{ maxHeight: "64px" }}
            src={Logo}
            alt="Pentabilities Logo"
          />
        </Header>

        <Modal
          title={t("misc.changelanguage")}
          onCancel={closeTranslationModal}
          onOk={closeTranslationModal}
          cancelText=""
          visible={translationModalVisible}
        >
          <Select style={{ width: "100%" }} defaultValue={i18n.language} onChange={selectLanguage}>
            <Option value="en">English</Option>
            <Option value="es">Castellano</Option>
            <Option value="ca">Català</Option>
          </Select>
        </Modal>

        <div className="fixed-widgets">
          <Popover style={{textAlign: 'center'}}
            content={
              <>
                {" "}
                <div>
                  <Badge>
                    <Avatar
                      style={{ backgroundColor: "#15395E" }}
                      size={40}
                      icon={<TranslationOutlined />}
                      onClick={openTranslationModal}
                    />
                  </Badge>
                  <p>Language</p>
                  <Divider />
                  <Badge>
                    <Avatar
                      style={{ backgroundColor: "#15395E" }}
                      size={40}
                      icon={<LogoutOutlined />}
                      onClick={onLogout}
                    />
                  </Badge>
                  <p>Logout</p>
                </div>
              </>
            }
          >
            <Badge>
              <Avatar
                style={{ backgroundColor: "#15395E" }}
                size={40}
                icon={<UserOutlined />}
              />
            </Badge>
          </Popover>
        </div>
        <PageContainer header={!_.isEmpty(hasTitle) ? hasTitle : {}}>
          <div className="penta-container-content">{childrenWithProps}</div>
        </PageContainer>
        <Footer className="penta-footer">
          <Typography>
            <Divider className="penta-divider-horizontal">
              <Text type="secondary">
                Versión: 1.1.0
                <Divider type="vertical" />
                Terms & Conditions
                <Divider type="vertical" />
                Privacy policy
              </Text>
            </Divider>
          </Typography>
        </Footer>
      </Layout>
    </>
  );
};

export default Main;
