import React from "react";
import { Result } from "antd";
import { useTranslation } from "react-i18next";

const Success = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Result
        status="success"
        title={t('misc.thanks')}
        subTitle={t('misc.thanksmsg')}
      />
    </>
  );
};

export default Success;
