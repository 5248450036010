const evaluation = {
    "evaluationId": "GenericOut",
    "steps": [{
        "stepName": "Final survey",
        "stepName_es": "Encuesta final",
        "stepName_ca": "Enquesta final",
        "intro": "Below are a number of statements that refer to the use of Pentabilities in the field of BMI-I. Rate each one from 1 to 5, depending on whether you agree or disagree (1, Disagree strongly; 5, Agree Strongly).",
        "intro_es": "A continuación hay una serie de afirmaciones que hacen referencia al uso de Pentabilities en el curso BMI-I. Valora cada una de ellas del 1 al 5, según si estás de acuerdo o no (1, Totalmente en desacuerdo; 5, Totalmente de acuerdo). ",
        "intro_ca": "A continuació hi ha una sèrie d’afirmacions que fan referància a l’ús de Pentabilities en la matèria de BMI-I. Valora cadascuna d’elles de l’1 al 5, segons si hi estàs d’acord o no (1, Totalment en desacord; 5, Totalment d’acord).",
        "questions": [{
                "id": "out-1-1",
                "label": "1.1. The tool (Pentabilities app) it's easy to use",
                "label_ca": "1.1. L’eina (Pentabilities app) és fàcil d'utilitzar",
                "label_es": "1.1. La herramienta (Pentabilities app) es fácil de usar",
                "layout": "vertical",
                "type": "radio",
                "options": [
                    { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo", "label_ca": "Totalment en desacord" },
                    { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo", "label_ca": "En desacord" },
                    { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión", "label_ca": "Neutral; sense opinió" },
                    { "value": "Agree", "label": "Agree", "label_es": "De acuerdo", "label_ca": "D'acord" },
                    { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo", "label_ca": "Totalment d'acord" }
                ],
                "required": true,
            },
            {
                "id": "out-1-2-1",
                "label": "1.2.1. The tool is quick to register in the sense of: Search for people",
                "label_es": "1.2.1. La herramienta es ágil para registrar a la hora de: Buscar personas",
                "label_ca": "1.2.1. L’eina és àgil per registrar a l’hora de: Buscar persones",
                "layout": "vertical",
                "type": "radio",
                "options": [
                    { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo", "label_ca": "Totalment en desacord" },
                    { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo", "label_ca": "En desacord" },
                    { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión", "label_ca": "Neutral; sense opinió" },
                    { "value": "Agree", "label": "Agree", "label_es": "De acuerdo", "label_ca": "D'acord" },
                    { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo", "label_ca": "Totalment d'acord" }
                ],
                "required": true,
            },
            {
                "id": "out-1-2-2",
                "label": "1.2.2. The tool is quick to register in the sense of Mark stars",
                "label_es": "1.2.2. La herramienta es ágil para registrar a la hora de Marcar estrellas",
                "label_ca": "1.2.2. L’eina és àgil per registrar a l’hora de Marcar estrelletes",
                "layout": "vertical",
                "type": "radio",
                "options": [
                    { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo", "label_ca": "Totalment en desacord" },
                    { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo", "label_ca": "En desacord" },
                    { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión", "label_ca": "Neutral; sense opinió" },
                    { "value": "Agree", "label": "Agree", "label_es": "De acuerdo", "label_ca": "D'acord" },
                    { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo", "label_ca": "Totalment d'acord" }
                ],
                "required": true,
            },
            {
                "id": "out-1-2-3",
                "label": "1.2.3. The tool is quick to register in the sense of Adding comments",
                "label_es": "1.2.3. La herramienta es ágil para registrar a la hora de Agregar comentarios",
                "label_ca": "1.2.3. L’eina és àgil per registrar a l’hora de Afegir comentaris",
                "layout": "vertical",
                "type": "radio",
                "options": [
                    { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo", "label_ca": "Totalment en desacord" },
                    { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo", "label_ca": "En desacord" },
                    { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión", "label_ca": "Neutral; sense opinió" },
                    { "value": "Agree", "label": "Agree", "label_es": "De acuerdo", "label_ca": "D'acord" },
                    { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo", "label_ca": "Totalment d'acord" }
                ],
                "required": true,
            },
            {
                "id": "out-1-3",
                "label": "1.3. The received reports provide easy to use information (students)",
                "label_es": "1.3. Los informes recibidos son exhaustivos y proveen información fácil de interpretar (alumnos)",
                "label_ca": "1.3. Els informes rebuts són exhaustius i donen información fàcil d’interpretar (alumnes)",
                "layout": "vertical",
                "type": "radio",
                "options": [
                    { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo", "label_ca": "Totalment en desacord" },
                    { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo", "label_ca": "En desacord" },
                    { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión", "label_ca": "Neutral; sense opinió" },
                    { "value": "Agree", "label": "Agree", "label_es": "De acuerdo", "label_ca": "D'acord" },
                    { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo", "label_ca": "Totalment d'acord" }
                ],
                "required": true,
            },
            {
                "id": "out-2-1",
                "label": "2.1. The behaviours are easy to understand",
                "label_es": "2.1. Los comportamientos son fáciles de entender",
                "label_ca": "2.1. Els comportaments són fàcils d’entendre",
                "layout": "vertical",
                "type": "radio",
                "options": [
                    { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo", "label_ca": "Totalment en desacord" },
                    { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo", "label_ca": "En desacord" },
                    { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión", "label_ca": "Neutral; sense opinió" },
                    { "value": "Agree", "label": "Agree", "label_es": "De acuerdo", "label_ca": "D'acord" },
                    { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo", "label_ca": "Totalment d'acord" }
                ],
                "required": true,
            },
            {
                "id": "out-2-2",
                "label": "2.2. I found adequate to use the behaviours to evaluate socioemotional skills",
                "label_es": "2.2. El uso de los comportamientos para evaluar habilidades socioemotionales me resulta adecuado",
                "label_ca": "2.2. L’ús dels comportaments per avaluar habilitats socioemocionals em resulta adequat",
                "layout": "vertical",
                "type": "radio",
                "options": [
                    { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo", "label_ca": "Totalment en desacord" },
                    { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo", "label_ca": "En desacord" },
                    { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión", "label_ca": "Neutral; sense opinió" },
                    { "value": "Agree", "label": "Agree", "label_es": "De acuerdo", "label_ca": "D'acord" },
                    { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo", "label_ca": "Totalment d'acord" }
                ],
                "required": true,
            },
            {
                "id": "out-2-3",
                "label": "2.3. There are enough behaviours to describe diverse situations",
                "label_es": "2.3. Hay suficientes comportamientos para describir diversas situaciones",
                "label_ca": "2.3. Hi ha comportaments suficients per descriure situacions divereses",
                "layout": "vertical",
                "type": "radio",
                "options": [
                    { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo", "label_ca": "Totalment en desacord" },
                    { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo", "label_ca": "En desacord" },
                    { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión", "label_ca": "Neutral; sense opinió" },
                    { "value": "Agree", "label": "Agree", "label_es": "De acuerdo", "label_ca": "D'acord" },
                    { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo", "label_ca": "Totalment d'acord" }
                ],
                "required": true,
            },
            {
                "id": "out-2-4",
                "label": "2.4. There are too many behaviours",
                "label_es": "2.4. Hay demasiados comportamientos",
                "label_ca": "2.4. Hi ha massa comportaments",
                "layout": "vertical",
                "type": "radio",
                "options": [
                    { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo", "label_ca": "Totalment en desacord" },
                    { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo", "label_ca": "En desacord" },
                    { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión", "label_ca": "Neutral; sense opinió" },
                    { "value": "Agree", "label": "Agree", "label_es": "De acuerdo", "label_ca": "D'acord" },
                    { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo", "label_ca": "Totalment d'acord" }
                ],
                "required": true,
            },
            {
                "id": "out-2-5",
                "label": "2.5. I'm missing behaviours to do a more complete evaluation",
                "label_es": "2.5. Me hacen falta comportamientos para hacer una evaluación más completa",
                "label_ca": "2.5. M’han faltat comportaments per fer una avaluació més completa",
                "layout": "vertical",
                "type": "radio",
                "options": [
                    { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo", "label_ca": "Totalment en desacord" },
                    { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo", "label_ca": "En desacord" },
                    { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión", "label_ca": "Neutral; sense opinió" },
                    { "value": "Agree", "label": "Agree", "label_es": "De acuerdo", "label_ca": "D'acord" },
                    { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo", "label_ca": "Totalment d'acord" }
                ],
                "required": true,
            },
            {
                "id": "out-2-6",
                "label": "2.6. If you missed behaviors, which would they be?",
                "label_es": "2.6. Si te han hecho falta comportamientos, ¿cuáles serían?",
                "label_ca": "2.6. Si has trobat a faltar comportaments, quins serien?",
                "layout": "vertical",
                "type": "textarea",
                "required": false,
            },
            {
                "id": "out-3-1",
                "label": "3.1. Through evaluation I'm more aware of socioemotional skills",
                "label_es": "3.1. Evaluando he ganado consciencia de las habilidades socioemocionales",
                "label_ca": "3.1. Avaluant he guanyat consciència de les habilitats socioemocionals ",
                "layout": "vertical",
                "type": "radio",
                "options": [
                    { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo", "label_ca": "Totalment en desacord" },
                    { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo", "label_ca": "En desacord" },
                    { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión", "label_ca": "Neutral; sense opinió" },
                    { "value": "Agree", "label": "Agree", "label_es": "De acuerdo", "label_ca": "D'acord" },
                    { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo", "label_ca": "Totalment d'acord" }
                ],
                "required": true,
            },
            {
                "id": "out-3-2",
                "label": "3.2. Through the reports I became aware of my socioemotional skills",
                "label_es": "3.2. Evaluando he ganado consciencia de las habilidades socioemocionales",
                "label_ca": "3.2. Rebent els informes he près consciència de les meves habilitats socioemocionals",
                "layout": "vertical",
                "type": "radio",
                "options": [
                    { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo", "label_ca": "Totalment en desacord" },
                    { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo", "label_ca": "En desacord" },
                    { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión", "label_ca": "Neutral; sense opinió" },
                    { "value": "Agree", "label": "Agree", "label_es": "De acuerdo", "label_ca": "D'acord" },
                    { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo", "label_ca": "Totalment d'acord" }
                ],
                "required": true,
            },
            {
                "id": "out-3-3",
                "label": "3.3. Using Pentabilities has been useful for me to understand and propose concrete improvements",
                "label_es": "3.3. Usar Pentabilities me ha sido útil para entender y proponerme mejoras concretas",
                "label_ca": "3.3. L'us de Pentabilities m’ha estat útil per entendre i proposar-me millores concretes",
                "layout": "vertical",
                "type": "radio",
                "options": [
                    { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo", "label_ca": "Totalment en desacord" },
                    { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo", "label_ca": "En desacord" },
                    { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión", "label_ca": "Neutral; sense opinió" },
                    { "value": "Agree", "label": "Agree", "label_es": "De acuerdo", "label_ca": "D'acord" },
                    { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo", "label_ca": "Totalment d'acord" }
                ],
                "required": true,
            },
            {
                "id": "out-3-4",
                "label": "3.4. Using Pentabilities has been useful for me to understand group's dynamics and the contributions of each team member",
                "label_es": "3.4. Usar Pentabilities me ha sido útil para entender dinámicas de equipo y las contribuciones de cada miembro",
                "label_ca": "3.4. L'us de Pentabilities m’ha estat útil per entendre dinàmiques d’equip i les contribucions de cada membre",
                "layout": "vertical",
                "type": "radio",
                "options": [
                    { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo", "label_ca": "Totalment en desacord" },
                    { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo", "label_ca": "En desacord" },
                    { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión", "label_ca": "Neutral; sense opinió" },
                    { "value": "Agree", "label": "Agree", "label_es": "De acuerdo", "label_ca": "D'acord" },
                    { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo", "label_ca": "Totalment d'acord" }
                ],
                "required": true,
            },
            {
                "id": "out-3-5",
                "label": "3.5. The time spent on the evaluation has been well spent",
                "label_es": "3.5. El tiempo dedicado a la evaluación ha estado bien invertido",
                "label_ca": "3.5. El temps dedicat en l’avaluació ha estat ben invertit",
                "layout": "vertical",
                "type": "radio",
                "options": [
                    { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo", "label_ca": "Totalment en desacord" },
                    { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo", "label_ca": "En desacord" },
                    { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión", "label_ca": "Neutral; sense opinió" },
                    { "value": "Agree", "label": "Agree", "label_es": "De acuerdo", "label_ca": "D'acord" },
                    { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo", "label_ca": "Totalment d'acord" }
                ],
                "required": true,
            },
            {
                "id": "out-3-6",
                "label": "3.6. The tool has allow me to do significant and complete evaluations",
                "label_es": "3.6. La herramienta me ha permitido hacer evaluaciones significativas y completas",
                "label_ca": "3.6. L’eina m’ha permès fer avaluacions significatives i completes",
                "layout": "vertical",
                "type": "radio",
                "options": [
                    { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo", "label_ca": "Totalment en desacord" },
                    { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo", "label_ca": "En desacord" },
                    { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión", "label_ca": "Neutral; sense opinió" },
                    { "value": "Agree", "label": "Agree", "label_es": "De acuerdo", "label_ca": "D'acord" },
                    { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo", "label_ca": "Totalment d'acord" }
                ],
                "required": true,
            },
            {
                "id": "out-3-7",
                "label": "3.7. Socioemotional skills reports can be helpful in finding a job",
                "label_es": "3.7. Los informes sobre las habilidades socioemocionales me pueden ser utilies a la hora de conseguir trabajo",
                "label_ca": "3.7. Els informes sobre les habilitats socioemocionals em poden ser útils a l’hora de trobar feina",
                "layout": "vertical",
                "type": "radio",
                "options": [
                    { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo", "label_ca": "Totalment en desacord" },
                    { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo", "label_ca": "En desacord" },
                    { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión", "label_ca": "Neutral; sense opinió" },
                    { "value": "Agree", "label": "Agree", "label_es": "De acuerdo", "label_ca": "D'acord" },
                    { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo", "label_ca": "Totalment d'acord" }
                ],
                "required": true,
            },
            {
                "id": "out-4",
                "label": "4. If you have any other comments, please share them with us.",
                "label_es": "4. Si tiene otros comentarios, compártalos con nosotros.",
                "label_ca": "4. Si teniu algun altre comentari, compartiu-los amb nosaltres.",
                "layout": "vertical",
                "type": "textarea",
                "required": false,
            }
        ]
    }]
};
export default evaluation;