import React, { useState, useEffect } from "react";

import Firebase from "../utilities/Firebase";
import UserStore from "../utilities/UserStore";

import { List } from "antd";

import _ from "lodash";

import questions from "../forms/MakerFestOut";
import SmartForm from "../components/SmartForm";

const ReportForm = (props) => {
  const [docList, setDocList] = useState([]);
  const onMount = async () => {
    props.setHeader({ title: "Reportes y encuesta" });
    let storage = Firebase.storage();
    var storageRef = storage.ref(UserStore.getUserName() + "/reports");
    storageRef
      .listAll()
      .then((res) => {
        let newDoc = {};

        res.items.forEach((itemRef) => {
          itemRef
            .getDownloadURL()
            .then((url) => {
              newDoc.url = url;
              itemRef
                .getMetadata()
                .then((meta) => {
                  newDoc.meta = meta;
                  let temp = _.merge(docList, [newDoc]);
                  setDocList([]);
                  setDocList(temp);
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
          // All the items under listRef.
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(onMount, []);

  return (
    <>
      <h1>Todos los reportes</h1>
      <List
        bordered
        dataSource={docList}
        renderItem={(item) => (
          <List.Item actions={[<a href={item.url}>Ver fichero</a>]}>
            {item.meta.name}
          </List.Item>
        )}
      />
      <br />
      <h1>Encuesta de finalización</h1>
      <SmartForm {...props} questions={props.questions} />
    </>
  );
};

export default ReportForm;
