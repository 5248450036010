import React, { useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import UserStore from "./UserStore";


const PublicRoute = ({ component: Component, layout: Layout, ...rest }) => {
    return(
        <Route {...rest}
        render={(props) => 
        UserStore.isLogged()?(<Redirect to="/main" />):(<><Layout><Component {...props} /></Layout></>)} />
    );
};

export default PublicRoute;