import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "antd/dist/antd.css";
import '@ant-design/pro-form/dist/form.css';

import "./index.css";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./utilities/PrivateRoute";
import PublicRoute from "./utilities/PublicRoute";
import Main from './layout/Main';
import Login from './layout/Login';
import OnlyForm from './pages/OnlyForm';
import Report from './pages/ReportForm';
import LoginPage from './pages/Login';
import Success from './pages/Success';
import MainPage from './pages/MainPage';
import './utilities/i18n';
import GenericQuestions from "./forms/GenericBaseLine";
import GenericOut from "./forms/GenericCloseSurvey";

ReactDOM.render(
  <BrowserRouter>
  <Switch>
    <PrivateRoute component={MainPage} layout={Main} path="/main" exact />
    <PrivateRoute component={OnlyForm} questions={GenericQuestions} layout={Main} path="/in-form" exact />
    <PrivateRoute component={OnlyForm} questions={GenericOut} layout={Main}  path="/out-form" exact />
    <PrivateRoute component={Report} layout={Main} path="/report" exact />
    <PrivateRoute component={Success} layout={Main} path="/success" exact />
    <PublicRoute component={LoginPage} layout={Login} path="/login" exact />
    <Redirect from="/" to="/main" />
  </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);
