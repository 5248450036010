import React, { useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import UserStore from "./UserStore";


const PrivateRoute = ({ component: Component, questions:Questions, layout: Layout, ...rest }) => {
    return(
        <Route {...rest}
        render={(props) => 
        !UserStore.isLogged()?(<Redirect to="/login" />):(<><Layout><Component {...props} questions={Questions} /></Layout></>)} />
    );
};

export default PrivateRoute;