import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Card } from "antd";
import { BuildTwoTone, AppstoreTwoTone } from "@ant-design/icons";
import {Link} from 'react-router-dom';

const MainPage = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <h1>{t("welcome")}</h1>
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <Link to={'/in-form'}>
          <Card className='penta-card' style={{ width: "100%", textAlign: "center" }}>
            <h2>{t("firsttitle")}</h2>
            <BuildTwoTone style={{ fontSize: "5vh" }} twoToneColor="#FE4100" />
            <h3>{t("first")}</h3>
          </Card>
          </Link>
        </Col>
        <Col className="gutter-row" span={12}>
        <Link to={'/out-form'}>
          <Card className='penta-card' style={{ width: "100%", textAlign: "center" }}>
          <h2>{t("alreadytitle")}</h2>
            <AppstoreTwoTone
              style={{ fontSize: "5vh" }}
              twoToneColor="#0665A2"
            />
            <h3>{t("already")}</h3>
          </Card>
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default MainPage;
