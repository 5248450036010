const evaluation = {
    "evaluationId": "GenericBaseLine",
    "steps": [{
            "stepName": "Personal information",
            "stepName_es": "Información personal",
            "intro": "Answer the following questions",
            "intro_es": "Contesta las siguientes preguntas",
            "questions": [{
                    "id": "gender",
                    "label": "Gender",
                    "label_es": "Género",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "label": "Female", "value": "Female", "label_es": "Femenino" },
                        { "label": "Male", "value": "Male", "label_es": "Masculino" }
                    ],
                    "required": true,
                    "step": "1"
                },

                {
                    "id": "birthyear",
                    "label": "Birth year",
                    "label_es": "Año de nacimiento",
                    "picker": "year",
                    "type": "date",
                    "required": true,
                    "step": "1"
                },
                {
                    "id": "birthplace",
                    "label": "Birth place",
                    "label_es": "Lugar de nacimiento",
                    "type": "text",
                    "required": true,
                    "step": "1"
                },
                {
                    "id": "ownedlevel",
                    "label": "What is the highest level of education you have completed?",
                    "label_es": "¿Cuál es el nivel educativo más alto del que ya te has graduado?",
                    "type": "radio",
                    "required": true,
                    "options": [
                        { "label": "High school or equivalent", "value": "High school or equivalent", "label_es": "ESO" },
                        { "label": "Vocational school", "value": "Vocational school", "label_es": "FP" },
                        { "label": "Bachelor's Degree", "value": "Bachelor's Degree", "label_es": "Licenciatura" },
                        { "label": "Master’s Degree", "value": "Master’s Degree", "label_es": "Master" },
                        { "label": "Ph.D. or higher", "value": "Ph.D. or higher", "label_es": "Doctorado" },
                        { "label": "Other (please specify)", "value": "Other", "label_es": "Otro (por favor, especifica)" }
                    ],
                    "layout": "vertical",
                    "step": "1"
                },
                {
                    "id": "ifotherlevel",
                    "label": "If other please specify",
                    "label_es": "Si has marcado otro, especifica",
                    "type": "text",
                    "required": false,
                    "step": "1"
                },
                {
                    "id": "fatheredlevel",
                    "label": "What is the highest educational level your FATHER has already graduated from?",
                    "label_es": "¿Cuál es el nivel educativo más alto del que ya se ha graduado tu PADRE?",
                    "type": "radio",
                    "required": true,
                    "options": [
                        { "label": "Less than high school", "value": "Less than high school", "label_es": "ESO" },
                        { "label": "High school or equivalent", "value": "High school", "label_es": "Bachillerato" },
                        { "label": "Vocational school", "value": "Vocational school", "label_es": "FP" },
                        { "label": "Bachelor's Degree", "value": "Bachelor's Degree", "label_es": "Licenciatura" },
                        { "label": "Master’s Degree", "value": "Master’s Degree", "label_es": "Master" },
                        { "label": "Ph.D. or higher", "value": "Ph.D. or higher", "label_es": "Doctorado" },
                        { "label": "Other", "value": "Other", "label_es": "Otro" }
                    ],
                    "layout": "vertical",
                    "step": "1"
                },
                {
                    "id": "motheredlevel",
                    "label": "What is the highest educational level your MOTHER has already graduated from?",
                    "label_es": "¿Cuál es el nivel educativo más alto del que ya se ha graduado tu MADRE?",
                    "type": "radio",
                    "required": true,
                    "options": [
                        { "label": "Less than high school", "value": "Less than high school", "label_es": "ESO" },
                        { "label": "High school or equivalent", "value": "High school", "label_es": "Bachillerato" },
                        { "label": "Vocational school", "value": "Vocational school", "label_es": "FP" },
                        { "label": "Bachelor's Degree", "value": "Bachelor's Degree", "label_es": "Licenciatura" },
                        { "label": "Master’s Degree", "value": "Master’s Degree", "label_es": "Master" },
                        { "label": "Ph.D. or higher", "value": "Ph.D. or higher", "label_es": "Doctorado" },
                        { "label": "Other", "value": "Other", "label_es": "Otro" }
                    ],
                    "layout": "vertical",
                    "step": "1"
                },

                {
                    "id": "working",
                    "label": "Are you currently working?",
                    "label_es": "¿Estás trabajando actualmente?",
                    "type": "radio",
                    "required": true,
                    "options": [{
                            "label": "Yes, I work part time",
                            "label_es": "Sí, trabajo a tiempo parcial",
                            "value": "Sí, trabajo a tiempo parcial"
                        },
                        {
                            "label": "Yes, I work full time",
                            "label_es": "Sí, trabajo jornada completa",
                            "value": "Sí, trabajo jornada completa"
                        },
                        {
                            "label": "No, but I'm looking for a job",
                            "label_es": "No, pero estoy buscando trabajo",
                            "value": "No, pero estoy buscando trabajo"
                        },
                        {
                            "label": "No, and I'm not looking for a job",
                            "label_es": "No, y no estoy buscando trabajo",
                            "value": "No, y no estoy buscando trabajo"
                        },
                        { "label": "Other", "value": "Other", "label_es": "Otro" }
                    ],
                    "layout": "vertical",
                    "step": "1"
                },
                {
                    "id": "people",
                    "label": "How many people live in your home?",
                    "label_es": "¿Cuántas personas habitan en tu hogar?",
                    "required": true,
                    "step": "1",
                    "type": "digit",
                    "precision": 0
                },
                {
                    "id": "peoplework",
                    "label": "How many people in your household work?",
                    "label_es": "¿Cuántas personas en tu hogar trabajan?",
                    "required": true,
                    "step": "1",
                    "type": "digit",
                    "precision": 0
                },
                {
                    "id": "avgincome",
                    "label": "Although you do not know the exact figure, could you tell me approximately the range in which the total monthly net income of your household is?",
                    "label_es": "Aunque no conozcas la cifra exacta, ¿podrías decirme aproximadamente el intervalo en que se encuentran los ingresos netos totales mensuales de tu hogar?",
                    "required": true,
                    "step": "1",
                    "type": "checkbox",
                    "layout": "vertical",
                    "options": [
                        "Less than 800€",
                        "From 500 to less than 1.000 €",
                        "From 1.000 to less than 1.500 €",
                        "From 1.500 to less than 2.000 €",
                        "From 2.000 to less than 2.500 €",
                        "From 2.500 to less than 3.000 €",
                        "From 3.000 to less than 5.000 €",
                        "From 5.000 to less than 7.000 €",
                        "From 7.000 to less than 9.000 €",
                        "9.000 or more €"
                    ],
                    "options_es": [
                        "Menos de 800€",
                        "De 500 a menos de 1.000 €",
                        "De 1.000 a menos de 1.500 €",
                        "De 1.500 a menos de 2.000 €",
                        "De 2.000 a menos de 2.500 €",
                        "De 2.500 a menos de 3.000 €",
                        "De 3.000 a menos de 5.000 €",
                        "De 5.000 a menos de 7.000 €",
                        "De 7.000 a menos de 9.000 €",
                        "9.000 y más €"
                    ]
                }
            ]
        },
        {
            "stepName": "Big Five personalities",
            "stepName_es": "Personalidades 'Big Five'",
            "intro": "Here are a number of characteristics that may or may not apply to you. Please, indicate the extent to which you agree or disagree with that statement. I am someone who...",
            "intro_es": "Presentamos una serie de características que pueden aplicarse o no en su caso. Indique en qué medida está de acuerdo o en desacuerdo con las siguientes declaraciones. Yo soy alguien...",
            "questions": [{
                    "id": "bg-1",
                    "label": "1. Tends to be quiet",
                    "label_es": "1. Que tiende a estar callado/a",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-2",
                    "label": "2. Is compassionate, has a soft heart",
                    "label_es": "2. Compasivo/a, con un gran corazón",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-3",
                    "label": "3. Tends to be disorganized",
                    "label_es": "3. Que tiende a ser desorganizado/a",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-4",
                    "label": "4. Worries a lot",
                    "label_es": "4. Que se preocupa mucho",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-5",
                    "label": "5. Is fascinated by art, music, or literature",
                    "label_es": "5. Fascinado por el arte, la música o la literatura",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-6",
                    "label": "6. Is dominant, acts as a leader.",
                    "label_es": "6. Dominante, que actúa como líder",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-7",
                    "label": "7. Is sometimes rude to others. ",
                    "label_es": "7. Que a veces es grosero/a con los demás",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-8",
                    "label": "8. Has difficulty getting started on tasks",
                    "label_es": "8. Al/A la que le cuesta empezar las tareas",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-9",
                    "label": "9. Tends to feel depressed, blue",
                    "label_es": "9. Que tiende a sentirse deprimido/a, melancólico/a",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-10",
                    "label": "10. Has little interest in abstract ideas",
                    "label_es": "10. Con poco interés por ideas abstractas",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-11",
                    "label": "11. Is full of energy",
                    "label_es": "11. Lleno/a de energía",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-12",
                    "label": "12. Assumes the best about people",
                    "label_es": "12. Qué piensa bien de la gente",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-13",
                    "label": "13. Is reliable, can always be counted on",
                    "label_es": "13. Fiable, con el/la que siempre se puede contar",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-14",
                    "label": "14. Is emotionally stable, not easily upset",
                    "label_es": "14. Emocionalmente estable, que no se altera con facilidad",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-15",
                    "label": "15. Is original, comes up with new ideas",
                    "label_es": "15. Original, que aporta ideas nuevas",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-16",
                    "label": "16. Is outgoing, sociable",
                    "label_es": "16. Abierto/a, sociable",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-17",
                    "label": "17. Can be cold and uncaring",
                    "label_es": "17. Que puede ser frío/a e insensible",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-18",
                    "label": "18. Keeps things neat and tidy",
                    "label_es": "18. Que mantiene todo limpio y ordenado",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-19",
                    "label": "19. Is relaxed, handles stress well",
                    "label_es": "19. Relajado/a, que gestiona bien el estrés",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-20",
                    "label": "20. Has few artistic interests",
                    "label_es": "20. Con pocos intereses artísticos",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-21",
                    "label": "21. Prefers to have others take charge",
                    "label_es": "21. Que prefiere que otros asuman la responsabilidad",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-22",
                    "label": "22. Is respectful, treats others with respect",
                    "label_es": "22. Respetuoso/a, que trata a los demás con respeto",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-23",
                    "label": "23. Is persistent, works until the task is finished",
                    "label_es": "23. Tenaz, que trabaja hasta terminar la tarea",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-24",
                    "label": "24. Feels secure, comfortable with self",
                    "label_es": "24. Que se siente seguro/a, cómodo/a consigo mismo/a",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-25",
                    "label": "25. Is complex, a deep thinker",
                    "label_es": "25. Complejo/a, de pensamientos profundos",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-26",
                    "label": "26. Is less active than other people",
                    "label_es": "26. Menos activo/a que otras personas",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-27",
                    "label": "27. Tends to find fault with others",
                    "label_es": "27. Que tiende a buscar los defectos de los demás",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-28",
                    "label": "28. Can be somewhat careless",
                    "label_es": "28. Que puede ser algo descuidado/a",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-29",
                    "label": "29. Is temperamental, gets emotional easily",
                    "label_es": "29. Temperamental, que se exalta fácilmente",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                },
                {
                    "id": "bg-30",
                    "label": "30. Has little creativity",
                    "label_es": "30. Con poca creatividad",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" }
                    ]
                }
            ]
        }, {
            "stepName": "Growth Mindset",
            "stepName_es": "Growth Mindset",
            "intro": "Indicate the extent to which you agree or disagree with the following statements.",
            "intro_es": "Indique en qué medida está de acuerdo o en desacuerdo con las siguientes declaraciones.",
            "questions": [{
                    "id": "gm-1",
                    "label": "1. You can learn new things, but you can’t really change how intelligent you are.",
                    "label_es": "1. Puedes aprender cosas nuevas, pero no puedes cambiar tu inteligencia.",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" }
                    ]
                },
                {
                    "id": "gm-2",
                    "label": "2. You can always change basic things about the kind of person that you are.",
                    "label_es": "2. Siempre puedes cambiar aspectos básicos sobre el tipo de persona que eres.",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" }
                    ]
                },
                {
                    "id": "gm-3",
                    "label": "3. No matter how much intelligence you have, you can always change it quite a bit.",
                    "label_es": "3. Independientemente de tu  inteligencia, siempre puedes cambiarla bastante.",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" }
                    ]
                },
                {
                    "id": "gm-4",
                    "label": "4. You can do things differently, but the important parts of who you are can’t really be changed.",
                    "label_es": "4. Puedes cambiar la manera de hacer las cosas, pero las partes importantes que te definen no se pueden cambiar.",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" }
                    ]
                },
                {
                    "id": "gm-5",
                    "label": "5. You are a certain kind of person, and there is not much that can really be done to change that.",
                    "label_es": "5. Eres un tipo de persona, y no hay mucho que se pueda hacer para cambiarlo.",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" }
                    ]
                },
                {
                    "id": "gm-6",
                    "label": "6. No matter what kind of person you are, you can always change substantially.",
                    "label_es": "6. Independientemente del tipo de persona que seas, siempre puedes cambiar de forma sustancial.",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" }
                    ]
                },
                {
                    "id": "gm-7",
                    "label": "7. Your intelligence is something very basic about you that can’t change very much.",
                    "label_es": "7. Tu inteligencia es un aspecto muy fundamental sobre ti que no puede cambiar mucho.",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" }
                    ]
                },
                {
                    "id": "gm-8",
                    "label": "8. You can always substantially change how intelligent you are.",
                    "label_es": "8. Siempre puedes cambiar sustancialmente tu nivel de inteligencia.",
                    "layout": "vertical",
                    "type": "radio",
                    "options": [
                        { "value": "Agree strongly", "label": "Agree strongly", "label_es": "Totalmente de acuerdo" },
                        { "value": "Agree", "label": "Agree", "label_es": "De acuerdo" },
                        { "value": "Neutral; no opinion", "label": "Neutral; no opinion", "label_es": "Neutral; sin opinión" },
                        { "value": "Disagree", "label": "Disagree", "label_es": "En desacuerdo" },
                        { "value": "Disagree strongly", "label": "Disagree strongly", "label_es": "Totalmente en desacuerdo" }
                    ]
                }
            ]
        }
    ]
};

export default evaluation;