import Firebase from './Firebase';

class UserStore {
    isLogged() {
        let answer = false;
        const user = (localStorage.getItem("userId"));
        if (user) {
            answer = true;
        }
        return answer;
    }
    getUserName() {
        return localStorage.getItem("userId");
    }
    async logUser(nData) {
        let result = '';
        let auth = Firebase.auth();
        try {
            let login = await auth.signInWithEmailAndPassword(nData.email, nData.password);
            localStorage.clear();
            localStorage.setItem("userId", login.user.uid);
            result = { code: 'success' }
        } catch (error) {
            result = error;
        }
        return result;
    }
    async logout() {
        let result;
        let auth = Firebase.auth();
        try {
            let logout = await auth.signOut();
            console.log(logout);
            localStorage.clear();
            result = { code: 'success' }
        } catch (error) {
            result = error;
        }
        return result;
    }
}

export default new UserStore();