import React from "react";

import UserStore from "../utilities/UserStore";

import ProForm, { ProFormText } from "@ant-design/pro-form";
import { LockTwoTone, SmileTwoTone } from "@ant-design/icons";

import Logo from "../assets/full-logo.svg";

const Login = (props) => {
  return (
    <>
      <div
        style={{
          width: 330,
          margin: "auto",
        }}
      >
        <ProForm
          onFinish={async (values) => {
            let answer = await UserStore.logUser(values);
            if(answer.code === 'success'){
                window.location.replace("/main");
            }
          }}
          submitter={{
            searchConfig: {
              submitText: "Iniciar sesión",
            },
            render: (_, dom) => dom.pop(),
            submitButtonProps: {
              size: "large",
              style: {
                width: "100%",
              },
            },
          }}
        >
          <h1
            style={{
              textAlign: "center",
            }}
          >
            <img
              style={{
                height: "100px",
                marginRight: 16,
              }}
              alt="logo"
              src={Logo}
            />
          </h1>

          <ProFormText
            fieldProps={{
              size: "large",
              prefix: <SmileTwoTone />,
            }}
            name="email"
            placeholder="Correo electrónico"
            rules={[
              {
                required: true,
                message: "email is mandatory!",
              },
            ]}
          />
          <ProFormText.Password
            fieldProps={{
              size: "large",
              prefix: <LockTwoTone />,
            }}
            placeholder="Contraseña"
            name="password"
            rules={[
              {
                required: true,
                message: "password is mandatory!",
              },
            ]}
          />
        </ProForm>
      </div>
    </>
  );
};

export default Login;
