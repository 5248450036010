import React, { useState, useEffect } from "react";

import Firebase from "../utilities/Firebase";
import UserStore from "../utilities/UserStore";

import { Alert, Button, Descriptions, Typography, Space } from "antd";

import ProForm, {
  StepsForm,
  ProFormText,
  ProFormSelect,
  ProFormTextArea,
  ProFormDatePicker,
  ProFormCheckbox,
  ProFormSlider,
  ProFormDateRangePicker,
  ProFormDependency,
  ProFormDigit,
  ProFormRadio,
} from "@ant-design/pro-form";

import { useTranslation } from "react-i18next";

import _ from "lodash";

const SmartForm = (props) => {
  const [questions, setQuestions] = useState([]);
  console.log(props);
  let stepFormObject = props.questions.steps;

  const { t, i18n } = useTranslation();

  const onMount = () => {
    let tempArray = [];
    _.forEach(stepFormObject, (step) => {
      tempArray = _.concat(tempArray, step.questions);
    });
    setQuestions(tempArray);
  };

  const questionRenderer = (nQuestion) => {
    switch (nQuestion.type) {
      case "radio":
        let filteredOptions = [];
        _.forEach(nQuestion.options, (option) => {
          filteredOptions.push({
            value: option.value,
            label:
              i18n.language == "en"
                ? option.label
                : option["label_" + i18n.language],
          });
        });

        return (
          <>
            <Descriptions.Item
              span={3}
              label={
                i18n.language == "en"
                  ? nQuestion.label
                  : nQuestion["label_" + i18n.language]
              }
            >
              <ProFormRadio.Group
                width="md"
                name={nQuestion.id}
                layout={nQuestion.layout}
                options={filteredOptions}
                rules={[
                  { required: true, message: t('misc.mandatory') },
                ]}
              />
            </Descriptions.Item>
          </>
        );
      case "slider":
        return (
          <>
            <Descriptions.Item
              span={3}
              label={
                i18n.language == "en"
                  ? nQuestion.label
                  : nQuestion["label_" + i18n.language]
              }
            >
              <ProFormSlider
                rules={[
                  { required: true, message: t('misc.mandatory') },
                ]}
                className="penta-slider"
                width="md"
                name={nQuestion.id}
                width="l"
                marks={nQuestion.marks}
                min={1}
                max={5}
              />
            </Descriptions.Item>
          </>
        );
      case "date":
        return (
          <>
            <Descriptions.Item
              span={3}
              label={
                i18n.language == "en"
                  ? nQuestion.label
                  : nQuestion["label_" + i18n.language]
              }
            >
              <ProFormDatePicker
                width="md"
                name={nQuestion.id}
                rules={[
                  { required: true, message: t('misc.mandatory')},
                ]}
                fieldProps={{ picker: "year", placeholder: "", format: "YYYY" }}
              />
            </Descriptions.Item>
          </>
        );
      case "text":
        return (
          <>
            <Descriptions.Item
              span={3}
              label={
                i18n.language == "en"
                  ? nQuestion.label
                  : nQuestion["label_" + i18n.language]
              }
            >
              <ProFormText
                width="md"
                name={nQuestion.id}
                rules={[
                  { required: nQuestion.required, message: t('misc.mandatory') },
                ]}
                placeholder=""
              />
            </Descriptions.Item>
          </>
        );
        case "textarea":
        return (
          <>
            <Descriptions.Item
              span={3}
              label={
                i18n.language == "en"
                  ? nQuestion.label
                  : nQuestion["label_" + i18n.language]
              }
            >
              <ProFormTextArea
                width="md"
                name={nQuestion.id}
                rules={[
                  { required: nQuestion.required, message: t('misc.mandatory') },
                ]}
                placeholder=""
              />
            </Descriptions.Item>
          </>
        );
      case "digit":
        return (
          <>
            <Descriptions.Item
              span={3}
              label={
                i18n.language == "en"
                  ? nQuestion.label
                  : nQuestion["label_" + i18n.language]
              }
            >
              <ProFormDigit
                rules={[
                  { required: true, message: t('misc.mandatory') },
                ]}
                width="md"
                placeholder=""
                name={nQuestion.id}
                min={1}
                fieldProps={{ precision: nQuestion.precision }}
              />
            </Descriptions.Item>
          </>
        );
      case "checkbox":
        return (
          <>
            <Descriptions.Item
              span={3}
              label={
                i18n.language == "en"
                  ? nQuestion.label
                  : nQuestion["label_" + i18n.language]
              }
            >
              <ProFormCheckbox.Group
                rules={[
                  { required: true, message: t('misc.mandatory') },
                ]}
                width="md"
                name={nQuestion.id}
                layout={nQuestion.layout}
                options={
                  i18n.language == "en"
                    ? nQuestion.options
                    : nQuestion["options_" + i18n.language]
                }
              />
            </Descriptions.Item>
          </>
        );
    }
  };

  const uploadData = async (values) => {
    console.log(values);
    let originalQuestions = [];

    _.forEach(stepFormObject, (step) => {
      originalQuestions = _.concat(originalQuestions, step.questions);
    });

    console.log(originalQuestions);
    let toUpload = {};
    _.forEach(values, (value, key) => {
      let selectedQuestion = _.find(originalQuestions, { id: key });
      if (selectedQuestion) {
        toUpload[key] = { ...selectedQuestion, value: value };
      }
    });
    toUpload = {
      ...toUpload,
      timestamp: Firebase.database.ServerValue.TIMESTAMP,
    };

    let db = Firebase.database();
    let result = await db
      .ref(
        "questionnaires/" +
          UserStore.getUserName() +
          "/" +
          props.questions.evaluationId
      )
      .push(toUpload);
    if (result.key) {
      window.location.replace("/success");
    }
  };

  useEffect(onMount, []);

  return (
    <>
      <StepsForm
        onFinish={async (values) => {
          let result = await uploadData(values);
        }}
        formProps={{
          validateMessages: {
            required: "此项为必填项",
          },
          submitter: { searchConfig: { submitText: "Hola" } },
        }}
        stepsProps={{ labelPlacement: "vertical" }}
        submitter={{
          render: ({ form, onSubmit, step, onPre }) => {
            return [
              step > 0 && (
                <Button
                  key="pre"
                  onClick={() => {
                    onPre?.();
                  }}
                >
                  {t('misc.back')}
                </Button>
              ),
              step < 2 && (
                <Button
                  key="next"
                  type="primary"
                  onClick={() => {
                    onSubmit?.();
                  }}
                >
                  {t('misc.next')}
                </Button>
              ),
              step > 1 && (
                <Button
                  key="next"
                  type="primary"
                  onClick={() => {
                    onSubmit?.();
                  }}
                >
                  {t('misc.finish')}
                </Button>
              ),
            ];
          },
        }}
      >
        {_.map(stepFormObject, (step) => {
          return (
            <>
              <StepsForm.StepForm
                key={step.stepName}
                title={
                  i18n.language == "en"
                    ? step.stepName
                    : step["stepName_" + i18n.language]
                }
                submitButtonProps={{ text: "Siguiente" }}
              >
                <Alert
                  style={{ marginBottom: "32px" }}
                  message={i18n.language=="en"?step.intro:step["intro_"+i18n.language]}
                  type="info"
                />
                <Descriptions bordered size="middle" layout="vertical">
                  {_.map(step.questions, (question) => {
                    return questionRenderer(question);
                  })}
                </Descriptions>
              </StepsForm.StepForm>
            </>
          );
        })}
      </StepsForm>
    </>
  );
};

export default SmartForm;
