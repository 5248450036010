import React, { useState, useEffect } from "react";

import SmartForm from "../components/SmartForm";

const OnlyFormPage = (props) => {
    return(<>
    
    <SmartForm {...props} questions={props.questions} />
    </>);
}

export default OnlyFormPage;