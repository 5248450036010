import React, { useState } from "react";

import { Layout  } from "antd";

const { Content } = Layout;

const Login = (props) => {
    const childrenWithProps = React.Children.map(props.children, (child) => {
      if (React.isValidElement(child)) {
        //TODO: We could add properties managed by the Layout e.g. loading spinner, help drawer, etc)
        return React.cloneElement(child);
      }
      return child;
    });

    return(
    <>
    <Content>
          <div style={{marginTop: '50px', marginBottom: '50px'}} className="penta-container-content">
            {childrenWithProps}
          </div>
        </Content>
    </>);
}

export default Login;