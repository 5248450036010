import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/firestore";
import "firebase/storage";


const config = {
    apiKey: "AIzaSyDEhMA3fVy8plEo2ePwM3rW_HSDm_V1OZk",
    authDomain: "givme5-2e84c.firebaseapp.com",
    databaseURL: "https://givme5-2e84c.firebaseio.com",
    projectId: "givme5-2e84c",
    storageBucket: "givme5-2e84c.appspot.com",
    messagingSenderId: "444363668141"
};

firebase.initializeApp(config);



if (process.env.NODE_ENV === 'development') {
    //firebase.auth().useEmulator('http://localhost:9099/');
    //firebase.firestore().useEmulator("localhost", 8080);
}



export default firebase;